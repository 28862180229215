import React, { Suspense, useEffect } from "react";
// routing
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// redux
import { useDispatch, useSelector } from "react-redux";
// types
import { SET_ADMIN } from "./store/admin/types";

import Admin from "./pages/Admin";
import TotalIncome from "./pages/TotalIncome";
import Creators from "./pages/Creators";
import HostHistory from "./pages/HostHistory";
import HostRequest from "./pages/hostRequest/HostRequest";

function App() {
  const dispatch = useDispatch();
  const { isAuth } = useSelector((state) => state.admin);
  const token = localStorage.getItem("TOKEN");
  const key = localStorage.getItem("KEY");

  const params = new URLSearchParams(window.location.search);
  const agencyId = params.get("id") || localStorage.getItem("agencyId");
  localStorage.setItem("agencyId", agencyId);

  useEffect(() => {
    if (!token && !key) return;
    dispatch({ type: SET_ADMIN, payload: token });
  }, [token, key, dispatch]);

  return (
    <div className="App">
      <Suspense fallback={""}>
        <BrowserRouter>
          <Routes>
            <Route path="/agencypanel/homePage" element={<Admin />} />
            <Route path="/agencypanel/Income" element={<TotalIncome />} />
            <Route path="/agencypanel/creators" element={<Creators />} />
            <Route path="/agencypanel/creatorRequest" element={<HostRequest />} />
            <Route path="/agencypanel/hosthistory" element={<HostHistory />} />
            <Route path="/" element={<Navigate to="/agencypanel/homePage" />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </div>
  );
}

export default App;
